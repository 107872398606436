.info-terminal-base__wrapper {
  background: url("../../../../../../res/images/base-wrapper-infoterminal.png") no-repeat;
  background-color: #C8102E;
  background-size: contain;
  background-position: center center;
  height: 100vh;
  width: 100vw;
}

.info-terminal--base {
  .betslip-row-container {
    background: #FFFFFF;
    display: flex;
    padding: 16px 24px;
    align-items: flex-start;
    border-radius: 16px;
    vertical-align: middle;
    margin-bottom: 24px;
    &.Canceled {
      background: rgba(255, 255, 255, 0.40);
    }
    &.Open {
      border-left: 8px solid #FACC15;
    }
    &.BuyBack {
      border-left: 8px solid #408C1F;
    }
    &.Canceled {
      border-left: 8px solid #0F172A;
    }
    &.ToBePaidBack {
      border-left: 8px solid #FFFF00;
    }
    &.Lost {
      border-left: 8px solid #F87171;
    }
    &.Won {
      border-left: 8px solid #4ADE80;
    }
    .betslip__betslip-details {
      flex: 1;
      padding-left: 16px;
      .betslip___date {
        color: #9D9D9D;
        font-size: 20px;
        font-weight: 400;
        line-height: 105%;
      }
      .betslip___teams {
        color: #4E4E4E;
        font-size: 26px;
        font-family: Tipico-Medium;
        font-weight: 500;
        margin-top: 8px;
      }
      .betslip___market {
        color: #4E4E4E;
        font-size: 20px;
        font-weight: 500;
        font-family: Inter;
        margin-top: 21px;
      }
    }
    .betslip__game-details {
      text-align: right;
      .game___score_time {
        color: #4E4E4E;
        font-family: Tipico-Medium;
        font-size: 22px;
      }
      .game___odds_status__wrapper {
        display: flex;
        min-height: 50px;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        .game___odds {
          font-size: 26px;
          color: #4E4E4E;
          font-family: Tipico-Medium;
          padding-top: 8px;
        }
        .game___status {
          font-size: 20px;
          font-weight: 600;
          line-height: 70%;
          font-family: Inter;
          border-radius: 16px;
          padding: 8px 16px;
          margin-left: 16px;
          &.Open {
            background: #FACC15;
            color: #4E4E4E;
          }
          &.BuyBack {
            background: #408C1F;
            color: #0F172A;
          }
          &.Canceled {
            background: #0F172A;
            color: #FFF;
          }
          &.ToBePaidBack {
            background: #FFFF00;
            color: #4E4E4E;
          }
          &.Lost {
            background: #F87171;
            color: #FFF;
          }
          &.Won {
            background: #4ADE80;
            color: #0F172A;
          }
        }
      }
    }
    .betslip__number {
      div {
        color: #4E4E4E;
        font-size: 26px;
        background: #E6E7E8;
        padding: 40px 32px;
        min-width: 70px;
        justify-content: center;
        display: flex;
        border-radius: 16px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        align-self: stretch;
      }
    }
  }
}

.info-terminal__betslip-sidebar___container {
  font-family: Tipico-Medium;
  font-weight: 500;
  .betslip-sidbar__footer___wrapper {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .betslip-sidbar__header___wrapper {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .betslip-sidbar__header___wrapper, .betslip-sidbar__footer___wrapper  {
    padding: 24px;
    text-align: center;
    font-size: 26px;
    box-shadow: -8px 0px 12px 0px rgba(0, 0, 0, 0.07), -32px 0px 76px 0px rgba(0, 0, 0, 0.15);
    &.CANCELED, &.REJECTED, &.EXPIRED, &.PAID_BACK, &.PAYBACK {
      color: #fff;
      background-color: #0F172A;
      .betslip-sidbar__header___icon {
        filter: brightness(0) saturate(100%) invert(92%) sepia(100%) saturate(290%) hue-rotate(218deg) brightness(114%) contrast(101%);
      }
    }
    &.DELIVERED, &.REQUESTED, &.PRELIMINARY {
      color: #4E4E4E;
      background-color: #EECA3D;
      .betslip-sidbar__header___icon {
        filter: brightness(0) saturate(100%) invert(33%) sepia(0%) saturate(358%) hue-rotate(264deg) brightness(90%) contrast(97%);
      }
    }
    &.LOST {
      color: #fff;
      background-color: #920C22;
    }
    &.WON, &.ALLOWED, &.PAID_OUT, &.PAYOUT {
      color: #fff;
      background-color: #408C1F;
    }
    &.BUY_BACK, &.BOUGHT_BACK {
      color: #408C1F;
      background: #EAFAF1;
    }
    .betslip-sidbar__header___icon {
      margin-left: 50%;
      display: block;
      transform: translate(-50%);
      background: url("../../../../../../res/images/info-icon.svg");
      margin-bottom: 16px;
      background-size: 40px 40px;
      height: 40px;
      width: 40px;
    }
  }
  .betslip-sidebar__betslip-info____wrapper {
    background: #fff;
    &.border-bottom-radius {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    &.border-top-radius {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }
    .betslip-sidebar__betslip-info____container {
      color: #9D9D9D;
      font-size: 26px;
      padding: 16px 24px;
      .betslip-sidebar__betslip-info___nr {
        line-height: 150%;
        span {
          color: #0F172A;
        }
      }
      .betslip-sidebar__betslip-info___date {
        line-height: 150%;
      }
    }
    .betslip-sidebar__betslip-info___system,
    .betslip-sidebar__betslip-info___max-odds,
    .betslip-sidebar__betslip-info___stake,
    .betslip-sidebar__betslip-info___logo,
    .betslip-sidebar__betslip-info___odd-boost {
      border-top: 1px solid #9D9D9D;
      padding: 16px 24px;
      label {
        color: #9D9D9D;
        display: block;
        font-size: 16px;
        font-family: Tipico-Medium;
        font-weight: 500;
        line-height: 143%;
        text-decoration-style: solid;
      }
      span {
        font-family: Inter;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        color: #4E4E4E;
        &.with-odd-booster {
          text-decoration: line-through;
          font-weight: 400;
          color: #9D9D9D;
          margin-right: 5px;
        }
      }
    }
    .betslip-sidebar__betslip-info___logo {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        background: url("../../../../../../res/images/tipico-logo.svg") no-repeat;
        filter: brightness(0) saturate(100%) invert(13%) sepia(87%) saturate(3722%) hue-rotate(340deg) brightness(103%) contrast(102%);
        display: block;
        width: 136px;
        height: 42px;
        margin: 32px 0;
      }
    }
    .betslip-sidebar__betslip-info___prize {
      text-align: center;
        span {
          display: block;
          width: 100%;
          font-family: Inter;
          font-size: 34px;
          font-weight: 700;
          color: #4E4E4E;
          line-height: normal;
        }
      label {
        color: #4E4E4E;
        font-weight: 400;
        font-size: 14px;
        font-family: Tipico-Regular;
        padding-bottom: 32px;
        display: block;
      }
    }
  }
}
