@use "../../../../../../../res/styles/pitch/settings/all";
@use "../../../../../../../res/styles/pitch/settings/colors";

.betgenius-iframe-wrapper {
  $betgenius-iframe-height: 188px;
  height: $betgenius-iframe-height;
  .betgenius-iframe {
    width: 100%;
    height: 100%;
    max-height: $betgenius-iframe-height;
    border: 0;
    box-sizing: border-box;
    background-color: colors.$black;
  }
}
