@use "../../../../../res/styles/pitch/settings/colors";

.football-scorecenter {
  background: colors.$grey-scoreboard;
  color: colors.$white;
  font-size: 24px;
  font-weight: 600;

  .soccer {
    padding: 17px 30px 17px 30px;
    line-height: 1.5;
  }

  .row {
    display: flex;
    flex: 1;
  }

  .bottom {
    padding-bottom: 15px;
  }

  .bottom-border {
    padding-bottom: 15px;
  }

  .square {
    background-color: colors.$grey-shadow;
    border-radius: 2px;
    display: flex;
    align-content: center;

    font-size: 20px;
    font-weight: 700;

    padding: 3px 6px;
    margin: 0 5px 0 5px;

    line-height: initial;
    align-self: flex-start;
  }

  .teamScore,
  .time {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  .time {
    padding-top: 5px;
    align-items: center;
    color: colors.$yellow-dark;
    font-weight: bold;
    font-size: 23px;
    text-transform: capitalize;
  }

  .right {
    text-align: right;
  }

  .icon {
    font-size: 16px;

    padding-left: 18px;
    position: relative;

    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      background-repeat: no-repeat;
      background-position: center center;

      position: absolute;
      content: "";
      left: 0;
      top: 0;

      height: 20px;
      width: 15px;
    }
  }

  .team {
    flex: 1;
  }
}

.redCard {
  &:before {
    background-image: url("icons/icon-sc-redcard.png");
  }
}

.yellowCard {
  &:before {
    background-image: url("icons/icon-sc-yellowcard.png");
  }
}

.corner {
  &:before {
    background-image: url("icons/icon-sc-corner.png");
  }
}
