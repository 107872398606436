@use "../../../../../res/styles/pitch/settings/colors";

.top-left-logo {
  content: url("tipico-logo.png");
  padding-left: 20px;
  padding-top: 20px;
}

.pitch-animation-title {
  color: colors.$white;
  font-size: 59px;
  font-weight: 600;
  padding-bottom: 10px;
  text-align: center;
  vertical-align: middle;
}

.pitch-animation-text {
  color: colors.$grey-silver;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  vertical-align: center;
}
