.customer-card__header___wrapper {
  display: flex;
  justify-content: space-between;
  background: #FFF;
  height: 100px;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  font-family: Tipico-Regular;
  padding: 16px 35px;
  span:nth-child(1) {
    color: #4E4E4E;
    font-size: 26px;
    text-align: left;
    min-width: 52%;
  }
  span:nth-child(2) {
    text-align: center;
    min-width: 24%;
  }
  span:nth-child(3) {
    text-align: right;
    min-width: 24%;
  }
  span {
    color: #9D9D9D;
    font-size: 22px;
    text-align: center;
  }
}

.customer-card__row___container {
  background: #FFF;
  padding: 8px 24px;
  &.bottom__radius {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    margin-bottom: 16px;
    padding: 8px 24px 16px 24px;
  }
  .customer-card__row___wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: #E6E7E8;
    border-radius: 16px;
    span:nth-child(1) {
      color: #4E4E4E;
      text-align: left;
      min-width: 52%;
      p {
        margin: 0;
        font-size: 20px;
        color: #9D9D9D;
        font-weight: 100;
      }
    }
    span:nth-child(2) {
      text-align: center;
      min-width: 24%;
    }
    span:nth-child(3) {
      text-align: right;
      min-width: 24%;
    }
    span {
      color: #4E4E4E;
      font-size: 26px;
    }
    .customer-card__row___status {
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      border-radius: 16px;
      padding: 4px 8px;
      margin-left: 24px;
      white-space: nowrap;
      &.DELIVERED, &.REQUESTED, &.PENDING, &.PRELIMINARY, &.ALLOWED {
        background: #FACC15;
        color: #0F172A;
      }
      &.BUY_BACK, &.BOUGHT_BACK {
        background: #DCFCE7;
        color: #15803D;
      }
      &.CANCELED, &.PAYBACK, &.PAID_BACK, &.TEST, &.REJECTED, &.EXPIRED {
        background: #0F172A;
        color: #fff;
      }
      &.LOST {
        background: #F87171;
        color: #0F172A;
      }
      &.WON, &.PAID_OUT, &.PAYOUT {
        background: #4ADE80;
        color: #0F172A;
      }
    }
  }
}

.customer-sidebar__wrapper {
  text-align: center;
  padding: 16px;
  color: #4E4E4E;
  background: #EECA3D;
  border-radius: 16px;
  box-shadow: -8px 0px 12px 0px rgba(0, 0, 0, 0.07), -32px 0px 76px 0px rgba(0, 0, 0, 0.15);
  .customer-sidebar__info-ico {
    margin-left: 50%;
    transform: translate(-50%);
    background: url("../../../../../../res/images/info-icon.svg");
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
  .customer-sidebar__pagination-text {
    font-size: 24px;
    font-family: Tipico-Regular;
    font-weight: 100;
  }
  .customer-sidebar__info {
    font-size: 26px;
    font-family: Tipico-Regular;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.placeholder__wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.no-data__placeholder___container {
  background: white;
  border-radius: 16px;
  width: 100%;
  height: fit-content;
  padding: 32px 64px;
  .no-data__placeholder___wrapper {
    display: flex;
    align-items: center;
    p {
      font-family: Tipico-Regular;
      font-size: 36px;
      color: #4E4E4E;
    }
    .no-data__placeholder___img {
      content: url("../../../../../../res/images/tipico.svg");
      margin-right: 56px;
      height: 146px;
      width: 108px;
    }
  }
}
