.-icon-tennis-serve {
    background: url(../../../../../../res/images/sport-ico/tennis-serve.svg) center
    center no-repeat;
    top: 9px;
    width: 15px;
    height: 15px;
}
.-icon-table_tennis-serve {
    background: url(../../../../../../res/images/sport-ico/table_tennis-serve.svg) center
    center no-repeat;
    top: 21px;
    width: 18px;
    height: 18px;
}

.-icon-serve {
    position: absolute;
    background-size: cover;
}

.--icon-tennis-serve__left {
    left: 8px;
}
.--icon-tennis-serve__right {
    right: 8px;
}

.--icon-table_tennis-serve__left {
    left: 1px;
}
.--icon-table_tennis-serve__right {
    right: 1px;
}
