.tennis__game--score {
  position: relative;
}
.tennis__ball--possess {
  position: absolute;
  top: 1px;
  left: initial;
  right: initial;
  width: 16px !important;
  height: 16px;
  background: url("../../../../../res/images/tennis.png") center center
    no-repeat;
  background-size: 16px 16px;
}
.tennis__ball--possess-teamleft {
  left: 2px;
}
.tennis__ball--possess-teamright {
  right: 2px;
}
.football__ball--possess {
  background: url("../../../../../res/images/soccer-icon45x45.png") center
    center no-repeat;
  background-size: 34px 34px;
}

.lwk-events--comments-col {
  width: calc(94.75% - 1px);
}
.lwk-events--comments-col .events__comment {
  width: 100%;
  text-align: center;
  line-height: 60px;
  color: #eeca3d;
}

.-vertflex {
  .cell--vertical-half {
    .progress-bar-wrapper {
      position: relative;
      width: calc(100% - 10px);
      height: 14px;
      background: #9a7b00;
      margin: 8px auto;
      border-radius: 4px;
      .progress-indicator {
        position: absolute;
        top: .5px;
        left: .5px;
        height: 12.5px;
        background: #eeca3d;
        border-radius: 4px;
      }
    }
  }
}

.blink {
  animation: blinker 1s linear infinite;
}

span.team1 {
  text-align: right;
}
span.team2 {
  text-align: left;
}
span.team-dash {
  width: 24px;
  min-width: 24px;
}

.-icon-play,
.-icon-penalty,
.-icon-rain {
  display: block;
  width: 50px;
  height: 50px;
  margin: 6px auto;
  background: url(../../../../../res/images/play.svg) no-repeat;
}
.-icon-penalty {
  margin-top: 5px;
  background: url(../../../../../res/images/penalty_alt.svg) no-repeat;
}
.-icon-rain {
  background: url(../../../../../res/images/rain.svg) no-repeat;
}
.-icon-team-flag {
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 10px;
}

.-icon-team-flag-fallback {
  background: url(../../../../../res/images/placeholder.svg) no-repeat;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 28px;
  background-size: 35px 30px;
  margin: 5px 12px;
}

@keyframes blinker {
  50% { opacity: 0; }
}


// EM-2020
.--team1239001, .--team4700292 {
  background: url(../../../../../res/images/flags/turkey.svg) no-repeat;
}
.--team4763292 {
  background: url(../../../../../res/images/flags/georgia.svg) no-repeat;
}
.--team4690292 {
  background: url(../../../../../res/images/flags/albania.svg) no-repeat;
}
.--team4740292 {
  background: url(../../../../../res/images/flags/armenia.svg) no-repeat;
}
.--team4482292 {
  background: url(../../../../../res/images/flags/cyprus.svg) no-repeat;
}
.--team4483292 {
  background: url(../../../../../res/images/flags/malta.svg) no-repeat;
}
.--team951301, .--team20279310, .--team6836210, .--team4707292 {
  background: url(../../../../../res/images/flags/italy.svg) no-repeat;
}
.--team961201, .--team13685410, .--team1952010, .--team2457901 {
  background: url(../../../../../res/images/flags/france.svg) no-repeat;
}
.--team951401, .--team4477292 {
  background: url(../../../../../res/images/flags/romania.svg) no-repeat;
}
.--team954101, .--team20278810, .--team20280310, .--team4702292 {
  background: url(../../../../../res/images/flags/wales.svg) no-repeat;
}
.--team953901, .--team13685110, .--team13685210, .--team4699292 {
  background: url(../../../../../res/images/flags/switzerland.svg) no-repeat;
}
.--team952801, .--team392924, .--team22446110, .--team22446310  {
  background: url(../../../../../res/images/flags/denmark.svg) no-repeat;
}
.--team1238901, .--team4712292 {
  background: url(../../../../../res/images/flags/finland.svg) no-repeat;
}
.--team4759292 {
  background: url(../../../../../res/images/flags/estonia.svg) no-repeat;
}
.--team951601, .--team13723510, .--team13723610, .--team4717292 {
  background: url(../../../../../res/images/flags/belgium.svg) no-repeat;
}
.--team954401 {
  background: url(../../../../../res/images/flags/russia.svg) no-repeat;
}
.--team617001, .--team20279410, .--team2288810 {
  background: url(../../../../../res/images/flags/england.svg) no-repeat;
}
.--team1000501, .--team20279010, .--team20279910, .--team4715292 {
  background: url(../../../../../res/images/flags/croatia.svg) no-repeat;
}
.--team951801, .--team41991110, .--team41991410, .--team4718292 {
  background: url(../../../../../res/images/flags/austria.svg) no-repeat;
}
.--team952301, .--team4777292 {
  background: url(../../../../../res/images/flags/macedonia.svg) no-repeat;
}
.--team4475292 {
  background: url(../../../../../res/images/flags/norway.svg) no-repeat;
}
.--team4776292 {
  background: url(../../../../../res/images/flags/lithuania.svg) no-repeat;
}
.--team7139292 {
  background: url(../../../../../res/images/flags/montenegro.svg) no-repeat;
}
.--team4706292 {
  background: url(../../../../../res/images/flags/latvia.svg) no-repeat;
}
.--team4693292 {
  background: url(../../../../../res/images/flags/ireland.svg) no-repeat;
}
.--team4786292 {
  background: url(../../../../../res/images/flags/northern-ireland.svg) no-repeat;
}
.--team4818292 {
  background: url(../../../../../res/images/flags/andorra.svg) no-repeat;
}
.--team4743292 {
  background: url(../../../../../res/images/flags/belarus.svg) no-repeat;
}
.--team4710292 {
  background: url(../../../../../res/images/flags/greece.svg) no-repeat;
}
.--team129264292 {
  background: url(../../../../../res/images/flags/gibraltar.svg) no-repeat;
}
.--team959701, .--team2312810, .--team2304610, .--team4705292 {
  background: url(../../../../../res/images/flags/netherlands.svg) no-repeat;
}
.--team950401, .--team41991210, .--team41991310, .--team4701292 {
  background: url(../../../../../res/images/flags/ukraine.svg) no-repeat;
}
.--team953401, .--team4695292 {
  background: url(../../../../../res/images/flags/scotland.svg) no-repeat;
}
.--team1050817501, .--team4714292, .--team6813410, .--team41991510 {
  background: url(../../../../../res/images/flags/czech-republic.svg) no-repeat;
}
.--team952901, .--team4703292 {
  background: url(../../../../../res/images/flags/poland.svg) no-repeat;
}
.--team952601, .--team4697292 {
  background: url(../../../../../res/images/flags/slovakia.svg) no-repeat;
}
.--team4760292 {
  background: url(../../../../../res/images/flags/faroe-islands.svg) no-repeat;
}
.--team4480292 {
  background: url(../../../../../res/images/flags/israel.svg) no-repeat;
}
.--team154426292 {
  background: url(../../../../../res/images/flags/kosovo.svg) no-repeat;
}
.--team4782292 {
  background: url(../../../../../res/images/flags/moldova.svg) no-repeat;
}
.--team4742292 {
  background: url(../../../../../res/images/flags/azerbaijan.svg) no-repeat;
}
.--team4716292 {
  background: url(../../../../../res/images/flags/bulgaria.svg) no-repeat;
}
.--team4772292 {
  background: url(../../../../../res/images/flags/kazakhstan.svg) no-repeat;
}
.--team953501, .--team1239501, .--team2292810, .--team2293010 {
  background: url(../../../../../res/images/flags/spain.svg) no-repeat;
}
.--team953701, .--team22444210, .--team23197610, .--team4688292 {
  background: url(../../../../../res/images/flags/sweden.svg) no-repeat;
}
.--team1005001, .--team4709292 {
  background: url(../../../../../res/images/flags/hungary.svg) no-repeat;
}
.--team953101, .--team1951710, .--team1951810, .--team4704292 {
  background: url(../../../../../res/images/flags/portugal.svg) no-repeat;
}
.--team617101, .--team13689010, .--team4711292 {
  background: url(../../../../../res/images/flags/germany.svg) no-repeat;
}

// Bundesliga
.--team489801, .--team1109501, .--team2538292, .--team12028801 {
  background: url(../../../../../res/images/teams/bundesliga/SC-Freiburg.svg) no-repeat;
}
.--team2591292, .--team2591290, .--team705652701, .--team1103112201 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Magdeburg.png) no-repeat;
  height: 55px;
  width: 55px;;
}
.--team489901, .--team2671292, .--team12028901 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Koeln.svg) no-repeat;
}
.--team490301, .--team2677292, .--team732316201 {
  background: url(../../../../../res/images/teams/bundesliga/VfB-Stuttgart.svg) no-repeat;
}
.--team490601, .--team2676292, .--team22969201 {
  background: url(../../../../../res/images/teams/bundesliga/Hamburger-SV.svg) no-repeat;
}
.--team490801, .--team2674292, .--team3286401 {
  background: url(../../../../../res/images/teams/bundesliga/Eintracht-Frankfurt.svg) no-repeat;
}
.--team491201, .--team2673292 {
  background: url(../../../../../res/images/teams/bundesliga/Borussia-Dortmund.svg) no-repeat;
}
.--team491501, .--team2542292, .--team3287801 {
  background: url(../../../../../res/images/teams/bundesliga/VfL-Bochum.svg) no-repeat;
}
.--team508901, .--team2681292 {
  background: url(../../../../../res/images/teams/bundesliga/Bayer-Leverkusen.svg) no-repeat;
}
.--team509001, .--team2530292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Schalke-04.svg) no-repeat;
}
//.--team509101, .--team22579901 {
//  background: url(../../../../../res/images/teams/bundesliga/Energie-Cottbus.svg) no-repeat;
//}
.--team509301, .--team2534292 {
  background: url(../../../../../res/images/teams/bundesliga/Werder-Bremen.svg) no-repeat;
}
.--team509601, .--team2528292, .--team22969301 {
  background: url(../../../../../res/images/teams/bundesliga/Hertha-BSC.svg) no-repeat;
}
.--team2675290, .--team2675292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Kaiserslautern.svg) no-repeat;
}
.--team517401, .--team2524292 {
  background: url(../../../../../res/images/teams/bundesliga/VfL-Wolfsburg.svg) no-repeat;
}
.--team534101, .--team2523292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Nuernberg.svg) no-repeat;
}
.--team1112401, .--team2556292, .--team36652401, .--team510001 {
  background: url(../../../../../res/images/teams/bundesliga/FSV-Mainz-05.svg) no-repeat;
}
.--team1113601, .--team11510701, .--team492601, .--team2541292 {
  background: url(../../../../../res/images/teams/bundesliga/Hannover-96.svg) no-repeat;
}
.--team1126501, .--team92904701, .--team2588292 {
  background: url(../../../../../res/images/teams/bundesliga/Fortuna-Duesseldorf.svg) no-repeat;
}
.--team2540292, .--team11512401, .--team491801 {
  background: url(../../../../../res/images/teams/bundesliga/Arminia-Bielefeld.svg) no-repeat;
}
.--team2547292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Union-Berlin.svg) no-repeat;
}
.--team2551292, .--team619237801, .--team534201 {
  background: url(../../../../../res/images/teams/bundesliga/Greuther-Fuerth.svg) no-repeat;
}
.--team2569292, .--team127022901 {
  background: url(../../../../../res/images/teams/bundesliga/TSG-1899-Hoffenheim.svg) no-repeat;
}
.--team2573292, .--team167447501, .--team704901101 {
  background: url(../../../../../res/images/teams/bundesliga/Holstein-Kiel.svg) no-repeat;
}
.--team2600292, .--team534413201, .--team80986401 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Augsburg.svg) no-repeat;
}
.--team2672292 {
  background: url(../../../../../res/images/teams/bundesliga/Bayern-Muenchen.svg) no-repeat;
}
.--team3153101, .--team2527292 {
  background: url(../../../../../res/images/teams/bundesliga/Borussia-Moenchengladbach.svg) no-repeat;
}
.--team3207801 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Nuernberg.svg) no-repeat;
}
.--team3213701, .--team518301, .--team2526292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-St-Pauli.svg) no-repeat;
}
.--team3288901, .--team711423601 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Union-Berlin.svg) no-repeat;
}
.--team2580292 {
  background: url(../../../../../res/images/teams/bundesliga/ULM_Standard.png) no-repeat;
}
.--team2590292 {
  background: url(../../../../../res/images/teams/bundesliga/PRM_Standard.png) no-repeat;
}
.--team5560201 {
  background: url(../../../../../res/images/teams/bundesliga/Bayern-Muenchen.svg) no-repeat;
}
.--team12028301, .--team146368401, .--team2557292 {
  background: url(../../../../../res/images/teams/bundesliga/Eintracht-Braunschweig.svg) no-repeat;
}
.--team36360292, .--team872019601, .--team666650601, .--team704901001 {
  background: url(../../../../../res/images/teams/bundesliga/RB-Leipzig.svg) no-repeat;
}
.--team55279901, .--team711423701, .--team2561292 {
  background: url(../../../../../res/images/teams/bundesliga/SC-Paderborn-07.svg) no-repeat;
}
.--team91706601, .--team2553292, .--team3286501, .--team625403401 {
  background: url(../../../../../res/images/teams/bundesliga/Karlsruher-SC.svg) no-repeat;
}
.--team128107501, .--team736888601, .--team5880292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Ingolstadt.svg) no-repeat;
}
.--team150810801, .--team577676601, .--team2576292, .--team704901701 {
  background: url(../../../../../res/images/teams/bundesliga/SV-Darmstadt-98.svg) no-repeat;
}
//.--team476201 {
//  background: url(../../../../../res/images/teams/bundesliga/RW-Ahlen.svg) no-repeat;
//}
//.--team491901 {
//  background: url(../../../../../res/images/teams/bundesliga/RW-Oberhausen.svg) no-repeat;
//}
.--team492001 {
  background: url(../../../../../res/images/teams/bundesliga/MSV-Duisburg.svg) no-repeat;
}
.--team492901, .--team2581292 {
  background: url(../../../../../res/images/teams/bundesliga/VfL-1899-Osnabrueck.svg) no-repeat;
}
.--team534101 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Nuernberg.svg) no-repeat;
}
//.--team582201 {
//  background: url(../../../../../res/images/teams/bundesliga/Alemannia-Aachen.svg) no-repeat;
//}
.--team2533292, .--team549591201 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Hansa-Rostock.svg) no-repeat;
}
.--team2566292, .--team22573801 {
  background: url(../../../../../res/images/teams/bundesliga/Erzgebirge-Aue.svg) no-repeat;
}
.--team2567292, .--team22573901, .--team39287010 {
  background: url(../../../../../res/images/teams/bundesliga/Jahn-Regensburg.svg) no-repeat;
}
.--team2595292, .--team128121201, .--team36738101 {
  background: url(../../../../../res/images/teams/bundesliga/Dynamo-Dresden.svg) no-repeat;
}
.--team5885292 {
  background: url(../../../../../res/images/teams/bundesliga/FC-Heidenheim.svg) no-repeat;
}
.--team6157292, .--team622654301 {
  background: url(../../../../../res/images/teams/bundesliga/SV-Sandhausen.svg) no-repeat;
}
//.--team23947292, .--team873728801 {
//  background: url(../../../../../res/images/teams/bundesliga/Wuerzburger-Kickers.svg) no-repeat;
//}
//.--team36738401 {
//  background: url(../../../../../res/images/teams/bundesliga/1860-München.svg) no-repeat;
//}
//.--team80986501 {
//  background: url(../../../../../res/images/teams/bundesliga/TuS-Koblenz.svg) no-repeat;
//}
//.--team92905901 {
//  background: url(../../../../../res/images/teams/bundesliga/FSV-Frankfurt.svg) no-repeat;
//}
.--team104749101, .--team147668201, .--team2597292, .--team2597290 {
  background: url(../../../../../res/images/teams/bundesliga/SV-Wehen-Wies.svg) no-repeat;
}
//.--team622654401 {
//  background: url(../../../../../res/images/teams/bundesliga/VfR-Aalen.svg) no-repeat;
//}

.--team2598292,.--team2598290 {
  background: url(../../../../../res/images/flags/elversberg.svg) no-repeat;
  height: 40px;
  width: 40px;
}
.--team4739292 {
  background: url(../../../../../res/images/flags/senegal.svg) no-repeat;
}

.--team606877101, .--team4792292 {
  background: url(../../../../../res/images/flags/qatar.svg) no-repeat;
}

.--team954901, .--team4757292 {
  background: url(../../../../../res/images/flags/ecuador.svg) no-repeat;
}

.--team7316201, .--team4713292 {
  background: url(../../../../../res/images/flags/england.svg) no-repeat;
}

.--team3117001, .--team4766292 {
  background: url(../../../../../res/images/flags/iran.svg) no-repeat;
}

.--team4724292, .--team104260 {
  background: url(../../../../../res/images/flags/united-states-of-america.svg) no-repeat;
}

.--team888603292 {
  background: url(../../../../../res/images/flags/wales.svg) no-repeat;
}

.--team103760601 {
  background: url(../../../../../res/images/flags/argentina.svg) no-repeat;
}

.--team1840101 {
  background: url(../../../../../res/images/flags/saudi-arabia.svg) no-repeat;
}

.--team36703701 {
  background: url(../../../../../res/images/flags/mexico.svg) no-repeat;
}

.--team952901 {
  background: url(../../../../../res/images/flags/poland.svg) no-repeat;
}

.--team952801, .--team4476292 {
  background: url(../../../../../res/images/flags/denmark.svg) no-repeat;
}

.--team4479196, .--team4479292 {
  background: url(../../../../../res/images/flags/bosnia-and-herzegovina.svg) no-repeat;
}
.--team4833292 {
  background: url(../../../../../res/images/flags/san-marino.svg) no-repeat;
}
.--team4830292 {
  background: url(../../../../../res/images/flags/liechtenstein.svg) no-repeat;
}
.--team4478292 {
  background: url(../../../../../res/images/flags/luxembourg.svg) no-repeat;
}

.--team4484292 {
  background: url(../../../../../res/images/flags/slovenia.svg) no-repeat;
}
.--team4708292 {
  background: url(../../../../../res/images/flags/iceland.svg) no-repeat;
}

.--team1238401, .--team4729292 {
  background: url(../../../../../res/images/flags/tunisia.svg) no-repeat;
}

.--team4481292, .--team961201, .--team2457901 {
  background: url(../../../../../res/images/flags/france.svg) no-repeat;
}

.--team888599292 {
  background: url(../../../../../res/images/flags/australia.svg) no-repeat;
}

.--team1446101 {
  background: url(../../../../../res/images/flags/japan.svg) no-repeat;
}

.--team4698292, .--team69238292 {
  background: url(../../../../../res/images/flags/spain.svg) no-repeat;
}

.--team888601292, .--team1008701 {
  background: url(../../../../../res/images/flags/costa-rica.svg) no-repeat;
}

.--team802101 {
  background: url(../../../../../res/images/flags/morocco.svg) no-repeat;
}

.--team1000501, .--team337084 {
  background: url(../../../../../res/images/flags/croatia.svg) no-repeat;
}

.--team973801 {
  background: url(../../../../../res/images/flags/canada.svg) no-repeat;
}

.--team961001 {
  background: url(../../../../../res/images/flags/cameroon.svg) no-repeat;
}

.--team955201 {
  background: url(../../../../../res/images/flags/brazil.svg) no-repeat;
}

.--team954301, .--team6355292 {
  background: url(../../../../../res/images/flags/serbia.svg) no-repeat;
}

.--team534186701 {
  background: url(../../../../../res/images/flags/uruguay.svg) no-repeat;
}

.--team766401 {
  background: url(../../../../../res/images/flags/south-korea.svg) no-repeat;
}

.--team4764292 {
  background: url(../../../../../res/images/flags/ghana.svg) no-repeat;
}
span[class*="--team"] {
  background-size: 94% 94%;
  background-position: center;
}
