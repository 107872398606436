@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.app__loader___container {
  background: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .app__loader___wrapper {
    text-align: center;
    color: #4E4E4E;
    font-family: Tipico-Medium;
    font-size: 24px;
    .app__loader___icon {
      content: url("../../../../res/images/tipico.svg");
      display: block;
      width: 65px;
      height: 88px;
      animation: rotate 2s infinite;
      margin: auto;
    }
  }
}

