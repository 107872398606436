$white: #fff;
$black: #000;
$dark-2: #303030;
$grey9d: #9d9d9d;
$greyca: #cacaca;
$grey45: #454545;
$grey1a: #1a1a1a;
$grey29: #292929;
$grey21: #212121;
$grey5f: #5f5f5f;
$greyf2: #f2f2f2;
$greyC7: #c7c7c7;
$greyDd: #ddd;
$dark-gray: #242424;
$yellow: #eeca3d;
$yellow6a532a: #6a532a;
$red: #c8102e;
$dark-blue-2: #0b597a;
$light-blue: #538495;
$dark-blue: #02587d;
$dark-blue-2: #0b597a;
$dark-yellow: #b87e1a;
$grey-opaque: rgba(167, 167, 167, 0.6);
