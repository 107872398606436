@use "./colors";

$eventTeamsFontSize: 3.25rem;
$betMarketTitleFontSize: 2.7rem;

html {
  font-size: 62.5%;
}
body {
  background: colors.$black;
  color: colors.$greyDd;
}
#root {
  font: {
    weight: 800;
    size: 2.95rem;
    family: SourceSansPro-Regular, sans-serif;
  }
}

.basic-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  div {
    box-sizing: border-box;
  }
}

.lwk-events-col,
.lwk-coeff-explain-col {
  width: auto;
  height: 60px;
  text-align: center;
  overflow: hidden;
}
.lwk-coeff-explain-col {
  height: 20px;
  span {
    font-size: 2rem;
    line-height: 10px;
  }
}
.lwk-events-col.h-40 span {
  line-height: 20px;
}
.lwk-events-col > span,
.lwk-events-col > div,
.lwk-events-col.h-40:nth-child(1) span,
.lwk-events-col.h-40:nth-child(2) span,
.lwk-events-col.h-40:nth-child(3) span {
  line-height: 60px;
}
.lwk-coeff-explain-col .h-20 > span {
  display: block;
  width: 100%;
  height: 20px;
  line-height: 16px;
  font-size: 2rem;
}
.lwk-coeff-explain-col:nth-child(1),
.lwk-events-col:nth-child(1) {
  width: 5.25%;
}
.lwk-coeff-explain-col:nth-child(2),
.lwk-events-col:nth-child(2) {
  width: 6.25%;
}
.coeff-explain-val {
  position: relative;
}
.lwk-coeff-explain-col:nth-child(3),
.coeff-explain-val:nth-child(1),
.coeff-explain-val:nth-child(3),
.lwk-events-col:nth-child(3) {
  width: 33.3%;
}
.coeff-explain-val:nth-child(2) {
  width: 33.4%;
}
.lwk-coeff-explain-col:nth-child(4),
.lwk-coeff-explain-col:nth-child(5),
.lwk-coeff-explain-col:nth-child(6),
.lwk-coeff-explain-col:nth-child(7),
.lwk-events-col:nth-child(4),
.lwk-events-col:nth-child(5),
.lwk-events-col:nth-child(6),
.lwk-events-col:nth-child(7) {
  width: 13.8%;
}

/* events */
.events__data {
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 60px;
  max-height: 120px;
  border-top: 1px solid #000;
  background: #151515;
  overflow: hidden;
}
.events__data:nth-child(even) {
  background: #2f2f2f;
}
.events__team {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: auto;
  height: 60px;
  padding: 0 4px;
  text-align: left;
  font: {
    size: $eventTeamsFontSize;
    weight: 400;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 60px;
  }
  span.-icon-event-point {
    width: 20px;
    height: 26px;
    border-radius: 2.6px;
    text-align: center;
    line-height: 26px;
    font-size: 22px;
  }
  span.-icon-event-point.-left {
    margin: 0 6px -2px;
  }
  span.-icon-event-point.-right {
    margin: 0 0 0 6px;
  }
  span.-icon-red_card {
    display: inline-block;
    background-color: #f30028;
    -ms-transform: rotate(4deg); /* IE 9 */
    transform: rotate(4deg);
  }
}
.events__team__bundesliga {
  padding: 0;
}
.events__team:nth-child(1) {
  width: calc(100% - 88px);
}
.events__team:nth-child(2) {
  width: 88px;
  text-align: center;
}
.lwk-events-col.-col-team-score {
  .events__team {
    width: calc(50% - 44px);
    span.bundesleague-team {
      width: auto;
    }
  }
  .events__team:nth-child(1) {
    justify-content: flex-end;
    text-align: right;
    span.bundesleague-team {}
  }
  .events__team:nth-child(3) {
    justify-content: flex-start;
    text-align: left;
    span.bundesleague-team {
      margin-right: -2px;
    }
  }
}

.events__data--row {
  width: 100%;
  height: 60px;
  margin: 0;
}

.full__time--score {
  line-height: 20px !important;
  font-size: 2.4rem;
  color: grey;
}

/* dialog */
.darkDialog {
  color: colors.$white;
  .rc-dialog {
    margin-top: 210px;
    width: 1021px;
  }
  .rc-dialog-content {
    background: colors.$dark-gray;
    border-radius: 0;
    padding: 0 10px 10px;
  }
  .rc-dialog-content > button {
    visibility: hidden;
  }
  .rc-dialog-header {
    background: transparent url("../../images/info-icon.png") no-repeat 98%
      center;
    border: 0;
    border-radius: 0;
    color: inherit;
    padding: 14px 10px;
  }
  .rc-dialog-title {
    font-size: 2.5rem;
    line-height: 2.5;
  }
  .rc-dialog-body {
    background: colors.$dark-2;
    font-size: 2.2rem;
    min-height: 448px;
    margin: 0;
    padding: 20px 15px 10px;
  }
  .rc-dialog-zoom-leave {
    animation-duration: 0.2s;
  }
  footer {
    text-align: center;
  }
  p {
    margin: 0 0 10px;
    padding: 0;
  }
  button {
    background: colors.$dark-2;
    border: 0;
    border-radius: 6px;
    color: colors.$white;
    font-size: 2.4rem;
    line-height: 32px;
    margin-right: 20px;
    min-width: 240px;
    padding: 10px;
    cursor: pointer;
  }
  button:focus {
    outline: none;
  }
  .rc-dialog-mask {
    background: colors.$grey-opaque;
  }
}
.darkDialog.errorDialog .rc-dialog-header {
  background: transparent url("../../images/red-icon.png") no-repeat 98% center;
}
.darkDialog.infoDialog button {
  display: block;
  width: 240px;
  margin: 200px auto 0;
  background: colors.$dark-blue;
}
.darkDialog.infoDialog button:hover {
  background: colors.$light-blue;
}
.darkDialog.errorDialog button {
  background: colors.$red;
}
.darkDialog.errorDialog button:hover {
  background: colors.$dark-yellow;
}

/* tweakers */
.-border-left-000-2 {
  border-left: 2px solid colors.$black;
}
.-border-top-000-2 {
  border-top: 1px solid colors.$black;
}
.-border-bottom-000-2 {
  border-bottom: 1px solid colors.$black;
}
.-color-yellow {
  color: #eeca3d;
  font-family: "SourceSansPro-Semibold", sans-serif;
}
.events__headers--titles {
  .lwk-events-col {
    .-text-left {
      text-align: left;
    }
    .-h-40 {
      height: 40px;
    }
    .-h-20 {
      height: 22px;
    }
  }
}
.events__data--row {
  .lwk-events-col {
    .-bg-custom {
      margin: 0 auto;
      width: calc(100% - 10px);
      height: 28px;
      border-radius: 4px;
    }
    .-f-sections-sm-en {
      font-size: 2.5rem;
    }
    .-f-sections-sm-de {
      font-size: 2.6rem;
    }
    .-f-sections-sm-fr {
      font-size: 2.2rem;
    }
    .-f-sections-sm-hr {
      font-size: 2.3rem;
    }
    .-f-sections-sm-tr {
      font-size: 2.6rem;
    }
  }
  .-vertflex {
    flex-direction: column;
  }
}
.-bg-darker {
  background: colors.$grey45;
  border-right: 2px solid colors.$grey5f;
}
.-bg-grey45 {
  background: colors.$grey45;
}
.-bg-reddish {
  background: colors.$red;
}
.-text-up {
  text-transform: uppercase;
}
.-text-right {
  text-align: right;
}
.-text-capitalise {
  text-transform: capitalize;
}
.-p-rel {
  position: relative;
}
.-txLeft-100 {
  transform: translateX(-100px);
}
.-f-26 {
  font-size: 2.6rem;
}
.-relative {
  position: relative;
}
.-specialbet {
  .events__team {
    width: calc(100% - 64px);
    text-align: left;
    color: #d6d2d2;
  }
}

.soccer-ico {
  background: url("../../images/soccer.svg");
  background-size: contain;
}

.penalty-mt-ico {
  background: url("../../images/penalty-mt.svg");
  background-size: contain;
}

.-vertflex {
  .cell--vertical-half {
    display: block;
    width: 100%;
    height: 30px;
    margin: 0 auto;
    font-size: 2.4rem;
    line-height: 30px;
    font-family: "SourceSansPro-Regular", sans-serif;
  }
  .-f-sections {
    font-size: 2.2rem;
    min-height: 28px;
    line-height: 28px;
  }
  .-fs-21 {
    font-size: 2.1rem;
  }
  .-fs-28 {
    font-size: 2.8rem;
  }
}
.-upcoming-event-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 6.5px auto;
  background-size: contain;
}
.-block {
  display: block;
}
.-line-height-60 {
  line-height: 60px;
}
.-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

#technical-span, #technical-span2 {
  width: auto;
  visibility: hidden;
}
#technical-span, #technical-span2 {
  font-size: $eventTeamsFontSize;
}
#technical-span2 {
  font-size: $betMarketTitleFontSize;
}

.red-card {
  text-align: center;
  font-size: 31px;
  background: #C23B21;
  border-radius: 4px;
  box-shadow: 0px 0px 9px -7px #000000;
}
