.change-indicator > span:nth-child(2) {
  display: block;
  position: absolute;
  bottom: 12px;
  right: calc((100% - 24px) / 2);
  width: 24px;
  height: 14px;
  margin: 0 auto;
}
.change-indicator > span:nth-child(1) {
  color: #525252;
}
.change-indicator > .fadein {
  transition: fadein 1s ease-in-out;
}

.events__data--row {
  .lwk-events-col {
    .extra-column {
      width: 25%;
    }
    .odds {
      padding: 0 .6% .6% .6%
    }
    .odds > span {
      display: block;
      width: 80%;
      height: 60px;
      line-height: 61px;
      margin: 0 auto;
      border-radius: 6px;
    }
    .extra-column-value {
      background: #5a5a5a;
      color: #fff;
      > span {
        width: auto;
      }
    }
    .up-start > span,
    .down-start > span,
    .up-glow > span,
    .up-active > span,
    .down-active > span,
    .up-finish > span,
    .down-finish > span {
      height: 50px;
      line-height: 51px;
      background: {
        position: 0 50%;
        repeat: no-repeat;
        size: auto 50px;
      };
    }
    .up-start > span {
      background-image: url('../../../../../res/images/bg-oddUp.jpg');
      animation: moveStart .28s;
    }
    .down-start > span {
      background-image: url('../../../../../res/images/bg-oddDown.jpg');
      animation: moveStart .28s;
    }
    .up-active > span,
    .down-active > span {
      background-repeat: repeat;
      animation: none;
    }
    .up-active > span,
    .up-finish > span {
      background-image: url('../../../../../res/images/bg-oddUp.jpg');
    }
    .down-active > span {
      background-image: url('../../../../../res/images/bg-oddDown.jpg');
    }
    .up-glow > span {
      background: {
        image: none;
        color: #2a5d09;
      };
      animation: upGlow 1.2s, upScale 1s;
      animation-iteration-count: 1;
    }
    .up-finish > span,
    .down-finish > span {
      background-size: 100% 0;
      animation: moveFinish .22s;
    }
    .down-finish > span {
      background-image: url('../../../../../res/images/bg-oddDown.jpg');
    }
  }
}

@keyframes moveStart {
  0%   {background-size: 100% 0;}
  100% {background-size: 100% 100%;}
}
@keyframes moveFinish {
  0%   {background-size: 100% 100%;}
  100% {background-size: 100% 0;}
}
@keyframes upGlow {
  0% {background-color: #2a5d09;}
  50% {background-color: #2b9617;}
  100% {background-color: #2a5d09;}
}
@keyframes upScale{
  0% {transform: scale(1);}
  50% {transform: scale(1.08);}
  100% {transform: scale(1);}
}
