@use "../../../../../../res/styles/pitch/settings/colors";
// sass-lint:disable indentation
.score-pitch {
  display: flex;
  background-image: url(scorecenter-background-filter.png),
    radial-gradient(circle, #2b9617 32%, #1b3c1e 100%);
  flex-direction: column;
  min-width: 300px;
  height: 188px;
  font-size: 13px;
  color: colors.$white;
  .top-section {
    font-weight: 500;
    margin-top: 5px;
  }
  .arrow.up:after {
    transform: rotate(180deg);
  }
  .pitch-container {
    padding: 5px 0;
    margin: 0;
    &.hide {
      display: none;
    }
  }
  .pitch-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &.top-section {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .pitch-column {
    flex: 1;
    position: relative;
    min-height: 15px;
    .event-right {
      display: inline-block;
      max-width: 90%;
    }
  }
  .pitch-square {
    background-color: rgba(colors.$black, 0.2);
    padding: 4px;
    margin: 0 10px;
    z-index: 1;
    font-weight: 500;
    &.transparent {
      background-color: transparent;
    }
  }
  .pitch-right {
    text-align: right;
  }
  .pitch-ht {
    background-color: rgba(colors.$white, 0.1);
  }
}
