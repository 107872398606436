@font-face {
  font-family: SourceSansPro-Regular;
  src: url("../res/fonts/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: SourceSansPro-Bold;
  src: url("../res/fonts/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: "SourceSansPro-Semibold";
  src: url("../res/fonts/SourceSansPro-Semibold.ttf");
}

@font-face {
  font-family: "Tipico-Regular";
  src: url("../res/fonts/Tipico-Regular.ttf");
}

@font-face {
  font-family: "Tipico-Medium";
  src: url("../res/fonts/Tipico-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../res/fonts/Inter.ttf");
}

html,
body,
// sass-lint:disable no-ids
#root {
  display: block;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: SourceSansPro-Regular, sans-serif;
  overflow: hidden;
}
