.match-ticker__row--area-container {
  background: #212121;
  min-height: 100%;
  .match-ticker__row--area-wrapper {
    height: 810px;
    flex-flow: wrap;
    padding: 35px 167px;
    overflow: hidden;
    flex-direction: column;
    .match-ticker__row--col1-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 1300px;
    }
  }
  .highlights__main--row {
    width: calc(100% - 40px);
  }
  .highlights__main--row:nth-child(n+7) {
    margin-left: 40px;
  }
}

.highlights__main--row {
  background: #3A3A3A;
  &.highlights__main--row-active {
    height: 0;
    animation: grow 0.5s ease-in forwards;
  }
  &.highlights__main--row-removed {
    margin-top: 16px;
    height: 150px;
  }
  &.highlights__main--row-focus {
    background: #535353;
  }
  border-radius: 8px;
  overflow: hidden;
  .animated__area--highlight-main {
    .timebox__highlight {
      position: relative;
      height: 150px;
      width: 132px;
      text-align: center;
      .time {
        color: #FFF;
        font-family: SourceSansPro-Semibold;
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
      }
      .time-desc {
        color: #FFF;
        width: 116px;
        background: #717171;
        bottom: 8px;
        border-radius: 4px;
        font-size: 26px;
        font-family: SourceSansPro-Regular;
        line-height: 30px;
        font-weight: 600;
        position: absolute;
        left: 10px;
      }
      .icon, .time, .penalty-box {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .flash {
        animation: BlinkerIcon 2s linear infinite;
      }
      .flash-time {
        animation: BlinkerTime 2s linear infinite;
      }
      .flash-penalty {
        animation: BlinkerTime 2s linear infinite;
      }
      .hide {
        display: none;
      }
    }
  }
  .highlights__competitor--rows-wrapper {
    width: 100%;
    border-radius: 8px;
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(1) {
      width: 80%;
    }
  }
}

.highlights__competitor--row-main {
  &:nth-child(1) {
    border-bottom: 1px solid black;
  }
  .highlights__competitor--score-wrapper {
    padding-right: 20px;
    color: white;
    .score-col {
      font-family: SourceSansPro-Semibold, sans-serif;
      font-weight: 600;
      transition: 0.5s all ease-in;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.goal {
        background: white;
        color: #3A3A3A;
      }
    }
  }
  .highlights__competitor--row-wrapper {
    width: 100%;
    color: white;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    .isUpdated {
      color: #EECA3D;
    }
    .col {
      display: grid;
    }
    .col:nth-child(1) {
      padding-left: 10px;
      margin-right: 20px; //2 cols
      position: relative;
      width: 40px;
      height: 40px;
      .team-icon {
        position: absolute;
      }
      .fallback-icon {
        position: absolute;
      }
    }
    .col:nth-child(2) {
      min-width: 33.32%; //4
    }
    .col:nth-child(3) {
      margin-left: 20px; //2 cols
    }
  }
}

@keyframes BlinkerIcon {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes BlinkerTime {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grow {
  to {
    height: 150px;
    margin-top: 16px;
  }
}