@use "../../../../res/styles/pitch/settings/colors";

.pitch-animation-screen {
  background: colors.$black;
  font-family: SourceSansPro-Regular, sans-serif;
  height: 100%;
  margin: 0;
  width: 100%;
}

.left-pitch-animation-box {
  display: block;
  float: left;
  height: calc(100% - 200px);
  overflow: hidden;
  padding: 20px 5px 0px 100px;
  width: calc(50% - 105px);
}

.right-pitch-animation-box {
  display: block;
  float: right;
  height: calc(100% - 200px);
  overflow: hidden;
  padding: 20px 100px 0px 5px;
  width: calc(50% - 105px);
}
