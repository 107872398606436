@use "../../../../../res/styles/pitch/settings/colors";

.iframe-header-box {
  background-color: colors.$grey-scoreboard;
  color: colors.$white;
  font-size: 25px;
  height: 75px;
  width: 100%;
  position: relative;
}

.statistics-icon {
  content: url("icon-stats.png");
}

.timeline-icon {
  content: url("icon-timeline.png");
}

.header-icon {
  position: absolute;
  top: 30px;
  left: 20px;
}

.statistics-text {
  position: absolute;
  top: 22px;
  left: 60px;
}
