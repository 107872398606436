// Primary Colors
$blue-steel: #4396b3;
$black: #000;
$grey: #828282;
$yellow: #eeca3d;
$red: #c8102e;
// Neutral Colors
$grey-shadow: #3a3a3a;
$grey-silver: #cacaca;
$white: #fff;
// Secondary Colors
$blue-light: #a5d2e1;
$yellow-dark: #e2b504;
//Component Colors
$blue-tennis: #eeca3d;
$yellow-tennis: #94d4ea;
$grey-scoreboard: #212121;
$header-background: #120002;
$grey-9d: #9d9d9d;
$grey-ca: #cacaca;
$grey45: #454545;
$grey1a: #1a1a1a;
$grey29: #292929;
$grey21: #212121;
