@use "../../../../../res/styles/pitch/settings/colors";

.scorecenterTennis {
  background: colors.$grey-scoreboard;
  font-size: 24px;
  font-weight: 600;
  color: colors.$white;
  height: 77px;
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 14px 50px 17px 50px;
  .hidden {
    display: none;
  }
  .date-time {
    text-align: left;
    text-transform: capitalize;
    font-size: 20px;
    justify-content: center;
    color: colors.$grey;
  }
  .set-number {
    text-align: left;
    text-transform: capitalize;
    font-size: 20px;
    justify-content: center;
    color: colors.$grey;
  }
  .teams {
    flex: 1;
    align-self: center;
    %team-selector {
      font-weight: bold;
      margin-right: 10px;
    }
    .team-one-color {
      @extend %team-selector;
      color: colors.$blue-tennis;
    }
    .team-two-color {
      @extend %team-selector;
      color: colors.$yellow-tennis;
    }
  }
  .circle {
    background: colors.$white;
    height: 8px;
    width: 8px;
    border-radius: 5px;
  }
  .score {
    font-size: 20px;
    color: colors.$grey;
    table {
      height: 100%;
      border-collapse: collapse;
    }
    %current {
      font-weight: 500;
      color: white;
    }
    .winner {
      @extend %current;
    }
    td {
      padding: 0 15px;
    }
    .current-set {
      padding: 0px;
    }
    .current-game {
      border-left: 1px solid colors.$grey;
    }
  }
  .live {
    color: colors.$blue-steel;
    font-size: 11px;
  }
}
