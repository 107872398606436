.info-terminal--base {
  display: flex;
  padding: 40px;
  height: 100%;
  background: #E6E7E8;
  .content__column {
    flex: 0 0 70%;
  }
  .info__column {
    padding-left: 40px;
  }
  .info-terminal--betslip-row-container {
    height: calc(100vh - 80px);
    overflow: hidden;
  }
}