@use "../../../../../res/styles/infoscreens/colors";

.title {
  position: relative;
  width: 100%;
  height: 100px;
  background-image: url(../../../../../res/images/header-left-bg.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 45% 100px;
}
.title,
.title * {
  font-family: "SourceSansPro-Semibold", sans-serif;
}
.title--theme-red {
  background-color: colors.$red;
  background-blend-mode: multiply;
  .title__header--content-text {
    color: colors.$white;
  }
}

.title--theme-light {
  background-color: colors.$white;
  text-transform: capitalize;
  .title__icon {
    filter: invert(48%) sepia(100%) saturate(8560%) hue-rotate(0deg)
      brightness(74%) contrast(100%);
    -webkit-filter: invert(48%) sepia(100%) saturate(8560%) hue-rotate(0deg)
      brightness(74%) contrast(100%);
  }
  .title__header--content-text {
    color: colors.$black;
  }
}

.title__header--content-text-warning {
  color: colors.$white !important;
}

.title--theme-dark {
  background-color: colors.$grey1a;
  background-blend-mode: soft-light;
  text-transform: capitalize;
  .title__header--content-text {
    color: colors.$white;
  }
}

.events--page-title_available-false {
  position: absolute;
  z-index: 1;
  background: transparent !important;
  .title__header--bundesliga {
    width: 330px;
    height: 36px;
    background: url(../../../../../res/images/bundesliga-white.svg) no-repeat;
  }
}

.title--theme-match-ticker {
  background: radial-gradient(29.79% 52.96% at 50% 50%, #373737 0%, #212121 100%);
  text-transform: capitalize;
  .title__header--content-text {
    color: colors.$white;
  }
}

.title--theme-em {
  background: url("../../../../../res/images/header.png");
  background-blend-mode: soft-light;
  text-transform: capitalize;
  .title__header--content-text {
    color: colors.$white;
  }
}

.title__icon {
  width: 130px;
  height: 100%;
  margin-left: 60px;
}

.title__icon--content {
  width: 130px;
  height: 40px;
  background: url(../../../../../res/images/tipico-logo.svg) center center
    no-repeat;
}
.title__page {
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: 90px;
  height: 60px;
  margin-right: 20px;
  font-size: 3.6rem;
  border-radius: 8px;
  text-align: center;
  padding: 2px 6px;
  box-sizing: border-box;
  color: #fff;
  .title__page-num, .title__clock {
    margin: 0;
    line-height: 40px;
  }
  .title__page-num {
    min-width: 60px;
    height: 60.4px;
    line-height: 60px;
    font-size: 3.4rem;
    background: rgb(22,22,22);
    background: linear-gradient(0deg, rgba(22,22,22,1) 0%, rgba(22,22,22,1) 49%, rgba(46,46,46,1) 51%, rgba(46,46,46,1) 100%);
    border-radius: 4px;
    color: #fff;
  }
  .title__clock {
    width: 100%;
    height: 60px;
    font-size: 3.6rem;
    margin-left: 10px;
    .tick {
      line-height: 59px;
      .tick-flip-panel {
        color: #d6d2d2;
        font-weight: bolder;
        background-color: #151515;
      }
      .tick-flip-panel:first-child {
        background: rgb(47,47,47);
      }
      .tick-flip-panel:last-child {
        background: rgb(21,21,21);
      }
      .tick-flip {
        text-indent: .2em;
        letter-spacing: .1em;
        .tick-flip-spacer {
          min-width: 140px;
        }
      }
    }
    .match-ticker {
      .tick-flip-panel {
        color: white;
      }
      .tick-flip-panel:first-child {
        background: radial-gradient(29.79% 52.96% at 50% 50%, #373737 0%, #212121 100%);
      }
      .tick-flip-panel:last-child {
        background: radial-gradient(29.79% 52.96% at 50% 50%, #373737 0%, #212121 100%);
      }
    }
  }
}
.title__header {
  width: auto;
  height: 100px;
  margin: 0 auto;
  justify-content: center;
}
.title__header--content {
  width: 100%;
  height: 45px;
  font-size: 3.6rem;
  &.match_ticker {
    font-size: 5.2rem;
  }
}
.title__header--content-icon {
  width: 45px;
  height: 45px;
  background: url(../../../../../res/images/last-minute.svg) 100% 100%;
  background-size: cover;
}
/* league specific icons */
.-icon-league {
  width: 41px;
  height: 33px;
  margin-right: 6px;
}
.basic-flex {
  .-icon-league {
    background-size: contain;
  }
}

.-icon-bundesliga {
  background: url(../../../../../res/images/league-ico/bundesliga.png) 100% 100%;
}

.-icon-2-bundesliga {
  background: url(../../../../../res/images/league-ico/2_bundesliga.svg) 100% 100% no-repeat;
  width: 50px;
  height: 50px;
}

.-icon-flag{
  width: 45px;
  height: 45px;
}

.-uk-flag {
  background: url(../../../../../res/images/flags/uk-flag.png) no-repeat;
}

.-it-flag {
  background: url(../../../../../res/images/flags/it-flag.png) no-repeat;
}

.-sp-flag {
  background: url(../../../../../res/images/flags/sp-flag.png) no-repeat;
}

.-fr-flag {
  background: url(../../../../../res/images/flags/fr-flag.png) no-repeat;
}

.-earth-flag {
  background: url(../../../../../res/images/flags/earth-flag.png) no-repeat;
}

.-euro-flag {
  background: url(../../../../../res/images/flags/euro-flag.png) no-repeat;
  width: 60px;
  height: 60px;
}

.-euro_cup-flag {
  background: url(../../../../../res/images/league-ico/trophy.svg) no-repeat;
  width: 60px;
  height: 60px;
}

/* end league icons */
.title__header--header-text {
  margin-left: 8px;
  font-size: 3.8rem;
  font-family: "SourceSansPro-semibold", sans-serif;
}
.title__header--subheader {
  width: 100%;
  height: 50px;
  padding: 4px 0 0;
  font-size: 2.4rem;
  color: colors.$grey9d;
  font-family: "SourceSansPro-Regular", sans-serif;
}

.title__worldcup--container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.title__header--bundesliga, .title__header--2-bundesliga {
  width: 295px;
  height: 33px;
  background: url(../../../../../res/images/bundesliga-title.svg) no-repeat;
}

.title__header--turkish_league {
  display: flex;
  align-items: center;
  gap: 10px;
  .image__container {
    height: 40px;
    content: url(../../../../../res/images/league-ico/Flag_of_Turkey.svg);
  }
}

.title__header--em_2024 {
  display: flex;
  align-items: center;
  gap: 10px;
  .image__container {
    height: 40px;
    content: url(../../../../../res/images/league-ico/trophy.svg);
  }
}

.title__header--worldcup22 {
  //width: 295px;
  //height: 100px;
  //content: url("wm22.png");
}
.col-sport {
  position: absolute;
  display: block;
  left: calc(50% - 30px);
}
