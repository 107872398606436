@use "../../../../res/styles/infoscreens/colors";

.report-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  background: url("../../../../res/images/bg-report.png") 100% 100% no-repeat;
  background-size: cover;
  .tipico-logo {
    position: absolute;
    top: 28px;
    left: 50px;
    width: 132px;
    height: 40px;
    background: url(../../../../res/images/tipico-logo.svg) 100% 100% no-repeat;
    background-size: contain;
  }
  .report__content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 460px;
    height: auto;
    margin: 29vh auto;
    .report__content-logo {
      width: 68px;
      height: 68px;
      background: url(../../../../res/images/logo-report.png) 100% 100%
        no-repeat;
      background-size: contain;
    }
    .report__content-info {
      margin-top: 24px;
      font-size: 30px;
      color: #fff;
      text-align: center;
      div:nth-child(2) {
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}

.report__preload {
  width: auto;
  height: 0 !important;
  overflow: hidden !important;
  background: url("../../../../res/images/bg-report.png") 100% 100% no-repeat;
}

.app-warning-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../../../res/images/error-bg.png") no-repeat left;
  background-size: cover;
  .app-warning-container {
    background: linear-gradient(180deg, rgba(23, 13, 11, 0.00) 0%, #120909 100%);
    width: 100%;
    height: 100%;
  }
  .app-warning__content {
    position: absolute;
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 80px;
    margin: 0 auto;
    text-align: center;
    .app-warning__content-main {
      font-size: 4rem;
    }
    .app-warning__content-sub {
      font-size: 2rem;
    }
  }
}
