.events__headers--titles {
  .lwk-events-col {
    .odd__title {
      width: 100%;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 2.7rem;
    }
    .odd__title--holder-explain {
      width: 100%;
      height: 20px;
    }
    .odd__title--explain {
      height: 20px;
      width: 33.3%;
      font-size: 2rem;
      line-height: 14px;
    }
    .-long-header {
      line-height: 20px;
      font-size: 2.28rem;
      padding-bottom: 4px;
      padding-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
