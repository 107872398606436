.events__headers {
  width: 100%;
  height: 60px;
  background: #454545;
  border-top: 1px solid #000;
  color: #fff;
}
.events__headers:nth-child(1) {
  border-top: 0;
  color: #2fa;
}
#root {
  .events__headers {
    flex-direction: column;
  }
}

.events__headers--titles {
  width: 100%;
  height: 60px;
  background: #5a5a5a;
}
.event__header--sport {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
}
.event__header--sport > span:first-child {
  display: inline-block;
  width: 41px;
  height: 41px;
  background-size: 36px 36px;
}
.-icon-football {
  background: url(../../../../../res/images/sport-ico/soccer.svg) center center
    no-repeat;
}
.-icon-futsal {
  background: url(../../../../../res/images/sport-ico/futsal.svg) center center
  no-repeat;
}
.-icon-tennis {
  background: url(../../../../../res/images/sport-ico/tennis.svg) center center
    no-repeat;
}
.-icon-basketball {
  background: url(../../../../../res/images/sport-ico/basketball.svg) center
    center no-repeat;
}
.-icon-american_football {
  background: url(../../../../../res/images/sport-ico/american_football.svg)
    center center no-repeat;
}
.-icon-table_tennis {
  background: url(../../../../../res/images/sport-ico/table_tennis.svg) center
    center no-repeat;
}
.-icon-upcoming {
  background: url(../../../../../res/images/last-minute.svg) center center
    no-repeat;
}
.-icon-australian_football {
  background: url(../../../../../res/images/sport-ico/australian_football.svg)
    center center no-repeat;
}
.-icon-badminton {
  background: url(../../../../../res/images/sport-ico/badminton.svg) center
    center no-repeat;
}
.-icon-baseball {
  background: url(../../../../../res/images/sport-ico/baseball.svg) center
    center no-repeat;
}
.-icon-boxen_mma {
  background: url(../../../../../res/images/sport-ico/mma.svg) center center
    no-repeat;
}
.-icon-boxing {
  background: url(../../../../../res/images/sport-ico/boxing.svg) center center
    no-repeat;
}
.-icon-chess {
  background: url(../../../../../res/images/sport-ico/chess.svg) center center
    no-repeat;
}
.-icon-copa_america_2021 {
  background: url(../../../../../res/images/sport-ico/copa_america_2021.svg)
    center center no-repeat;
}
.-icon-cricket {
  background: url(../../../../../res/images/sport-ico/cricket.svg) center center
    no-repeat;
}
.-icon-darts, .-icon-darts_bdo {
  background: url(../../../../../res/images/sport-ico/darts.svg) center center
    no-repeat;
}
.-icon-em_2021 {
  background: url(../../../../../res/images/sport-ico/em_2021.svg) center center
    no-repeat;
}
.-icon-esports {
  background: url(../../../../../res/images/sport-ico/esports.svg) center center
    no-repeat;
}
.-icon-golf {
  background: url(../../../../../res/images/sport-ico/golf.svg) center center
    no-repeat;
}
.-icon-handball {
  background: url(../../../../../res/images/sport-ico/handball.svg) center
    center no-repeat;
}
.-icon-ice_hockey {
  background: url(../../../../../res/images/sport-ico/ice_hockey.svg) center
    center no-repeat;
}
.-icon-field_hockey {
  background: url(../../../../../res/images/sport-ico/field_hockey.svg) center
  center no-repeat;
}
.-icon-poolbilliard {
  background: url(../../../../../res/images/sport-ico/poolbilliard.svg) center
    center no-repeat;
}
.-icon-rugby {
  background: url(../../../../../res/images/sport-ico/rugby.svg) center center
    no-repeat;
}
.-icon-aussie_rules {
  background: url(../../../../../res/images/sport-ico/rugby.svg) center center
  no-repeat;
}
.-icon-snooker {
  background: url(../../../../../res/images/sport-ico/snooker.svg) center center
    no-repeat;
}
.-icon-cycling {
  background: url(../../../../../res/images/sport-ico/cycling.svg) center center
    no-repeat;
}
.-icon-volleyball {
  background: url(../../../../../res/images/sport-ico/volleyball.svg) center
    center no-repeat;
}
.-icon-beach_soccer {
  background: url(../../../../../res/images/sport-ico/beach_soccer.png) center
    center no-repeat;
}
.-icon-beach_volleyball {
  background: url(../../../../../res/images/sport-ico/beach_volleyball.png) center
    center no-repeat;
}
.-icon-waterpolo {
  background: url(../../../../../res/images/sport-ico/waterpolo.svg) center
    center no-repeat;
}
.-icon-wintersport {
  background: url(../../../../../res/images/sport-ico/wintersport.svg) center
    center no-repeat;
}
.-icon-wm_2022 {
  background: url(../../../../../res/images/sport-ico/wm_2022.svg) center center
    no-repeat;
}
